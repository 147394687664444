@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  padding: 30px;
  border-radius: 5px;
}
@media( min-width: 600px) {
  .container {
    max-width: 500px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    /*border: 1px solid steelblue;*/
    padding: 30px;
    border-radius: 5px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Navbar */
nav {
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;  
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  background-color: #1434A4;
  color: #f4f4f4;
}

.toph4 {
  margin: 5px 0px 0px 15px;
}

.mobile-ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  text-align: right;
  position: fixed;
  top: 50px;
  background-color: #f4f4f4;
}

.nav-a {
  color: #1434A4;
  text-decoration: none;
}

.desktop-ul {
  display: none;
}

li {
  margin: 8px 25px 10px 0px;
}

.hamburger {
  height: 20px;
  width: auto;
  margin: 10px;
}

@media (min-width: 600px){
  nav {
    position: fixed;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;  
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: #f4f4f4;
    color: #1434A4;
  }
  
  .toph4 {
    margin: 0px 0px 0px 15px;
    color: #000;
  }
  
  .mobile-ul {
    display: none;
  }
  
  .desktop-ul {
    display: flex;
    list-style-type: none;
    margin-left: 20px;
  }
  
  li {
    margin: 10px 25px 10px 0px;
  }
  li:hover {
    cursor: pointer;
    color: #000;
  }
  
  .hamburger {
    display: none;
  }

  .nav-a {
    text-decoration: none;
  }
  .nav-a:hover {
    cursor: pointer;
    color: #000;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.btn {
  width: 125px;
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
@media (min-width: 600px){
  .btn {
    width: 135px;
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
  }  
}
.editbtn {
  width: 260px;
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

@media (min-width: 600px){
  .editbtn {
    width: 135px;
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
  }  
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.completed-h2 {
  color: red;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.grocery-item {
  margin: 7px 0px 5px 0px;
}

.edit-item-container {
  display: flex;
  flex-direction: column;
}

.editItemDiv {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.editItemButton {
  background: #000;
  color: #fff;
  border: none;
  padding: 2px 10px;
  margin: 5px 5px 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.deleteFa {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: center;
}

.select {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;}

footer {
  margin-top: 30px;
  text-align: center;
}